import config from '@/config/app.config.js' // 引入配置文件
import contractAbi from '../abi/MaiDistribute.json' // 分发合约
import web3Obj from '../utils/web3'
import store from '@/store'

const contractAddress = config.maiDistribute
const contractInstance = new web3Obj.web3.eth.Contract(contractAbi, contractAddress)

/**
 * 獲取mai余额
 * @return amount
 */
const donateBalance = async () => {
  try {
    var balance = await contractInstance.methods
      .donateBalance()
      .call({})
    balance = web3Obj.fromWei(balance)
    return web3Obj.successResult(balance)
  } catch (error) {
    return web3Obj.failResult(error)
  }
}

/**
 * 分发
 */
const processDonate = async () => {
  try {
    const resp = await contractInstance.methods
      .processDonate()
      .send({ from: store.state.myAcount, ...web3Obj.gasPriceObj })
    return web3Obj.successResult(resp)
  } catch (error) {
    return web3Obj.failResult(error)
  }
}

export default {
  donateBalance,
  processDonate
}
