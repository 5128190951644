import Web3 from 'web3'
import store from '@/store'
import config from '@/config/app.config.js' // 引入配置文件
import contractAbi from '../abi/DonateProcessor.json' // 质押锁仓合约abi
import Web3Obj from '@/utils/web3.js'

const provider = config.provider

let web3
try {
  web3 = web3 = new Web3(window.ethereum)
} catch {
  web3 = new Web3(new Web3.providers.HttpProvider(provider))
}
const currentAccount = store.state.myAcount // 当前钱包账户
const contractInstance = new web3.eth.Contract(contractAbi, config.donateProcessor)

/**
 * donateAmount
 */
const donateAmount = async () => {
  try {
    var amount = await contractInstance.methods
      .donateAmount()
      .call({ })
    amount = Web3Obj.fromWei(amount)
    return Web3Obj.successResult(amount)
  } catch (error) {
    return Web3Obj.failResult(error)
  }
}

/**
 * 获取donate地址
 */
const DONATE = async () => {
  try {
    const resp = await contractInstance.methods.DONATE().call({})
    return Web3Obj.successResult(resp)
  } catch (error) {
    return Web3Obj.failResult(error)
  }
}
/**
 * 分发
 */
const processDonate = async (address) => {
  await Web3Obj.connectWallet()
  try {
    const resp = await contractInstance.methods
      .processDonate(address)
      .send({ from: currentAccount || store.state.myAcount, ...Web3Obj.gasPriceObj })
    return Web3Obj.successResult(resp)
  } catch (error) {
    return Web3Obj.failResult(error)
  }
}

export default {
  donateAmount,
  DONATE,
  processDonate
}
